import React, { FC, memo, useCallback, useContext, useMemo } from 'react'
import { ScreenContext, SolidButton } from 'react-components'
import { useTranslation } from '../../../hooks/locale'
import ListingSectionTransport from '../../../transports/common/listing-section-transport'
import TopRecommendationVerticalCard from './vertical-card'
import TopRecommendation from './horizontal-card'
import {
    Container,
    CtaButtonText,
    CtaContainer,
    CtaLink,
    MainContainer,
    MainTitle,
    SliderContainer,
    StyledCarouselProvider,
    StyledSlider,
    SortOptionsContainer,
} from './styles'
import ExternalLink from '../../common/external-link'
import SortOption from '../sort-option'
import { Slide } from 'pure-react-carousel'
import AnalyticsService from '../../../services/analytics-service'
import { landingPageEventCategories } from '../../../services/analytics-service/categories'

interface TopRecommendedSectionProps {
    topRecommendedSection: ListingSectionTransport
    url: string
    locationName: string
    offerHeading?: string
}

const getMobileCards = (recommendedObjects: ListingSectionTransport['objects']) => {
    return recommendedObjects.map((recommendedObject, index) => (
        <Slide index={recommendedObject.id} key={`${index}_${recommendedObject.id}`}>
            <TopRecommendationVerticalCard
                key={recommendedObject.id}
                recommendedObject={recommendedObject}
                position={index}
            />
        </Slide>
    ))
}

const TopRecommendationsSection: FC<TopRecommendedSectionProps> = ({
    topRecommendedSection,
    locationName,
    offerHeading,
}) => {
    const { heading, objects, sortOptions, cta } = topRecommendedSection
    const { t } = useTranslation()
    const { isMobile, isDesktop } = useContext(ScreenContext)
    const mobileCards = useMemo(() => getMobileCards(objects), [objects])

    const viewAllAccommodationsButtonPosition = {
        TOP: 'top',
        BOTTOM: 'bottom',
    }

    const _handleViewAllAccommodationsClick = useCallback(
        (ctaPosition: string) => {
            const eventCategory =
                ctaPosition === viewAllAccommodationsButtonPosition.TOP
                    ? landingPageEventCategories.topRecommendationsSection.TOP_CTA_VIEW_RECOMMENDATIONS
                    : landingPageEventCategories.topRecommendationsSection.BOTTOM_CTA_VIEW_RECOMMENDATIONS

            AnalyticsService.trackClickEvent(eventCategory, `${cta?.url}`)
            document.location.href = `${t('traumBaseUrl')}${cta?.url}`
        },
        [cta?.url, t],
    )

    const _getPreposition = useCallback(
        (offerHeading?: string) => {
            if (offerHeading === null || offerHeading === undefined) {
                return null
            }
            const trimmedTitle = offerHeading.replace(`${t('regionHeadingPrefix')}`, '')
            if (trimmedTitle === locationName) {
                return null
            }
            return `${trimmedTitle.replace(locationName, '')}`
        },
        [locationName, t],
    )

    const preposition = _getPreposition(offerHeading)
    const viewAllAccommodationsCtaText = `${t('ctaButtonTextTopRecommendation')} ${preposition}
    ${locationName === t('world') ? '' : locationName} ${t('lookAt')}`

    return (
        <MainContainer id={'top-recommendation'}>
            <Container>
                <MainTitle>
                    {heading}
                    {isMobile && !!cta && (
                        <ExternalLink as={CtaLink} href={`${t('traumBaseUrl')}${cta.url}`} noCrawl={cta.noCrawl}>
                            {t('ctaLinkText')}
                        </ExternalLink>
                    )}
                </MainTitle>
                <SortOptionsContainer>
                    <SortOption sortOptions={sortOptions} />
                    {isDesktop && (
                        <CtaContainer>
                            <SolidButton
                                onClick={() =>
                                    _handleViewAllAccommodationsClick(viewAllAccommodationsButtonPosition.TOP)
                                }
                                padding="8px 16px"
                                hoverEffect
                            >
                                <CtaButtonText>{viewAllAccommodationsCtaText}</CtaButtonText>
                            </SolidButton>
                        </CtaContainer>
                    )}
                </SortOptionsContainer>
                {!isMobile &&
                    objects.map(recommendedObject => {
                        return <TopRecommendation key={recommendedObject.id} recommendedObject={recommendedObject} />
                    })}
                {isMobile && (
                    <SliderContainer>
                        <StyledCarouselProvider
                            totalSlides={objects.length}
                            visibleSlides={1}
                            naturalSlideWidth={1}
                            naturalSlideHeight={1}
                            isIntrinsicHeight
                            lockOnWindowScroll
                            dragStep={1}
                            center
                        >
                            <StyledSlider $transitionTime={0.2}>{mobileCards}</StyledSlider>
                        </StyledCarouselProvider>
                    </SliderContainer>
                )}
                {!isMobile && (
                    <CtaContainer>
                        <SolidButton
                            onClick={() =>
                                _handleViewAllAccommodationsClick(viewAllAccommodationsButtonPosition.BOTTOM)
                            }
                            padding={'15px 30px'}
                            hoverEffect
                        >
                            <CtaButtonText>{viewAllAccommodationsCtaText}</CtaButtonText>
                        </SolidButton>
                    </CtaContainer>
                )}
            </Container>
        </MainContainer>
    )
}

export default memo(TopRecommendationsSection)
