import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ScreenContext, SolidButton } from 'react-components'
import { ThemeContext } from 'styled-components'
import Cookies from 'js-cookie'
import {
    HeroSectionForRegionTransport,
    HeroSectionTransport,
    HeroTransport,
} from '../../transports/region-landing-page-transport'
import {
    Anchor,
    ButtonContentWrapper,
    ButtonWrapper,
    Container,
    ContentWrapper,
    LogoWrapper,
    Overlay,
    SearchBoxWrapper,
    StickyContainer,
    SubtitleWrapper,
    TextWrapper,
    TitleWrapper,
    RegionsAutocompleteSuggestionPickerWrapper,
    RedArrow,
    RedCard,
    Title,
    BgImageContainer,
    BgPictureContainer,
} from './searchbar-section-styles'
import DateInputBox from './searchbar-datepicker'
import SearchBarGuestStepper from './searchbar-guest-stepper'
import { getHistoryObject, getImageUrl } from './utils'
import AnalyticsService from '../../services/analytics-service'
import { useTranslation } from '../../hooks/locale'
import { landingPageEventCategories } from '../../services/analytics-service/categories'
import { commonPageEventActions, landingPageEventActions } from '../../services/analytics-service/actions'
import { BaseSearchbarHistoryItem, SearchbarHistoryItem } from '../../redux/searchbar/types'
import { CallbackFunction } from '../../common/types'
import { getFilterFromHref } from '../../utils/request-utils'
import { getSearchDate } from '../../utils/miscellaneous-utils'
import { useSearchUrl } from '../../hooks/search'
import { useOpen } from '../search-result-listing/header-section/searchbar/hooks'
import { Location } from '../search-result-listing/header-section/types'
import RegionsAutocompleteSuggestionTransport from '../../transports/regions-autocomplete-suggestion-transport'
import RegionsAutocompleteSuggestionPicker from '../search-result-listing/header-section/searchbar/regions-autocomplete-suggestion-picker'
import { LocationPickerMode } from '../common/location-picker/types'
import { LogoImage } from './header-section/header-section-styles'
import ButtonTransport from '../../transports/common/button-transport'
import { CookieManagerService } from '../../services'
import { format } from 'date-fns'
// import NextImage from '../common/next-image'

export declare type START_DATE = 'startDate'
export declare type END_DATE = 'endDate'

export interface DatepickerState {
    focusedInput: START_DATE | END_DATE | null
    startDate: Date | null
    endDate: Date | null
}

interface Props {
    count: number
    locationName: string
    addToHistory: (historyItem: BaseSearchbarHistoryItem) => void
    searchFunction: CallbackFunction
    url: string
    hero: HeroSectionTransport
    heroSection: HeroTransport
    heroSectionForRegion: HeroSectionForRegionTransport
    logo?: ButtonTransport
    regionTitle?: string
}

const SearchbarSection: React.FC<Props> = ({
    hero,
    count,
    locationName,
    addToHistory,
    searchFunction,
    url,
    heroSection,
    heroSectionForRegion,
    logo,
    regionTitle,
}) => {
    const { location: initialSearch, arrival, departure, searchLabel } = heroSection
    const { isDesktop, isMobile, isWideScreen } = useContext(ScreenContext)
    const theme = useContext(ThemeContext)
    const { t } = useTranslation()

    const [regionId, setRegionId] = useState<number>(null)
    const [inputValue, setInputValue] = useState<string>(locationName)
    const [guestsValue, setGuestsValue] = useState<number>(0)
    const [minGuestsValue, setMinGuestsValue] = useState<number>(0)
    const [maxGuestsValue, setMaxGuestsValue] = useState<number>(12)
    const [datepickerState, setDatepickerState] = useState<DatepickerState>({
        startDate: null,
        endDate: null,
        focusedInput: 'startDate',
    })

    const [warning, setWarning] = useState<boolean>(false)

    // Sticky logic starts
    const stickyRef = useRef<HTMLDivElement>(null)
    const [sticky, setSticky] = useState(false)

    const handleScroll = useCallback(() => {
        if (stickyRef.current && stickyRef.current.getBoundingClientRect().top < 0) {
            setSticky(true)
        } else {
            setSticky(false)
        }
    }, [stickyRef])

    useEffect(() => {
        if (isMobile) {
            return
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll, isMobile])
    // Sticky logic ends

    useEffect(() => {
        // Initialize dates and person count from cookie
        const searchParams = Cookies.get('searchParams')
        if (searchParams) {
            const [arrivalDate, departureDate, adults] = searchParams.split('|')
            const currentDate = new Date()
            const parsedArrivalDate = arrivalDate ? new Date(arrivalDate) : null

            // Check if the arrival date is in the past
            if (parsedArrivalDate && parsedArrivalDate < currentDate) {
                Cookies.remove('searchParams')
            } else {
                setDatepickerState({
                    startDate: arrivalDate ? new Date(arrivalDate) : null,
                    endDate: departureDate ? new Date(departureDate) : null,
                    focusedInput: 'startDate',
                })
                setGuestsValue(adults ? Number.parseInt(adults) : 0)
            }
        }
    }, [])

    const { getSearchUrl } = useSearchUrl()

    const handleSearchClick = useCallback(async () => {
        if (!inputValue) {
            setWarning(true)
            return
        }
        const eventCategory = `${landingPageEventCategories.searchSection.SEARCH_BOX} - ${url}`
        const eventAction = commonPageEventActions.searchSection.searchBox.SEARCH
        AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: String(inputValue) })

        addToHistory(getHistoryObject(datepickerState.startDate, datepickerState.endDate, guestsValue, 0, inputValue))

        const searchUrl = await getSearchUrl(getFilterFromHref(window.location.href), {
            regionId,
            search: inputValue,
            arrival: getSearchDate(datepickerState.startDate),
            departure: getSearchDate(datepickerState.endDate),
            adults: guestsValue,
            children: 0,
        })

        const { startDate, endDate } = datepickerState

        if (startDate && endDate && guestsValue) {
            const arrivalDate = format(startDate, 'yyyy-MM-dd')
            const departureDate = format(endDate, 'yyyy-MM-dd')
            const personCount = guestsValue.toString()
            const kidsCount = 0

            CookieManagerService.setCookie(
                'searchParams',
                `${arrivalDate}|${departureDate}|${personCount}|${kidsCount}|${inputValue}`,
                {
                    path: '/',
                },
            )
        } else {
            CookieManagerService.deleteCookie('searchParams')
        }

        document.location.href = `${t('traumBaseUrl')}${searchUrl}`
    }, [
        addToHistory,
        datepickerState.endDate,
        datepickerState.startDate,
        getSearchUrl,
        guestsValue,
        inputValue,
        regionId,
        t,
        url,
    ])

    const { slide, description } = hero
    const { title, subtitle } = description
    const [locationPickerError, setLocationPickerError] = useState('')
    const initialLocation: Location = useMemo(() => ({ regionId: null, search: inputValue ?? '' }), [inputValue])

    const [location, setLocation] = useState(initialLocation)

    const {
        isOpen: isLocationPickerOpen,
        onOpen: onLocationPickerOpen,
        onClose: onLocationPickerClose,
    } = useOpen(false)

    const _handleLocationPickerOpen = useCallback(() => {
        setWarning(false)
        setLocationPickerError('')
        onLocationPickerOpen()
    }, [onLocationPickerOpen])

    const _handleLocationInputChange = useCallback((input: string) => {
        // Optimisation for not changing the state when input is the same. Prevent unnecessary re-renders.
        setLocation(location => (location.search !== input ? { regionId: null, search: input } : location))
        setInputValue(inputValue => (inputValue !== input ? input : inputValue))
    }, [])

    const _handleLocationInputSelect = useCallback(
        (input: string) => {
            _handleLocationInputChange(input)
            onLocationPickerClose()
        },
        [_handleLocationInputChange, onLocationPickerClose],
    )

    const _handleLocationItemSelect = useCallback(
        (item: RegionsAutocompleteSuggestionTransport) => {
            // Tracking for suggestion click
            const eventCategory = `${landingPageEventCategories.searchSection.SEARCH_BOX} - ${url}`
            const eventAction = landingPageEventActions.searchSection.searchBox.LOCATION
            AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: url })

            setInputValue(item.title)
            setLocation({ search: item.title, regionId: item.regionId })
        },
        [url],
    )

    const _handleHistoryItemSelect = useCallback(
        async (historyItem: SearchbarHistoryItem) => {
            const { search, arrival, departure, adults, children } = historyItem

            setLocation(location => (location.search !== search ? { regionId: null, search: search } : location))

            const searchUrl = await getSearchUrl(getFilterFromHref(window.location.href), {
                regionId: regionId,
                search: search,
                arrival: getSearchDate(arrival),
                departure: getSearchDate(departure),
                adults: adults,
                children: children,
            })

            document.location.href = `${t('traumBaseUrl')}${searchUrl}`
        },
        [getSearchUrl, regionId, t],
    )

    const _getRegionTitlePrefix = useCallback(
        (regionTitle?: string) => {
            if (regionTitle === null || regionTitle === undefined) {
                return null
            }
            const trimmedTitle = regionTitle.replace(`${t('regionHeadingPrefix')}`, '')
            if (trimmedTitle === locationName) {
                return null
            }
            return `${t('regionHeading') + ' ' + trimmedTitle.replace(locationName, '')}`
        },
        [locationName, t],
    )

    const regionTitlePrefix = _getRegionTitlePrefix(regionTitle)
    const smallImage = getImageUrl('small-mobile', slide)
    const mediumImage = getImageUrl('medium-mobile', slide)
    const largeImage = getImageUrl('large-mobile', slide)
    const desktopImage = getImageUrl('desktop', slide)
    const wideScreenImage = getImageUrl('wide-screen', slide)
    const heading =
        heroSectionForRegion && heroSectionForRegion.heading
            ? heroSectionForRegion.heading
            : title && subtitle
            ? `${title} ${subtitle}`
            : title || subtitle

    return (
        <Container id="searchbar">
            {/* will uncommnt once certificate issue gets resolved for some hostnames */}
            {/*{!!slide?.image && (*/}
            {/*    <NextImage*/}
            {/*        src={'https://static2.svc.staging.tfw.io/fl/v2.3.1/assets/img/hero/default.jpg'}*/}
            {/*        alt="hero image"*/}
            {/*        priority*/}
            {/*        quality={isDesktop ? 50 : 75}*/}
            {/*    />*/}
            {/*)}*/}
            {!!slide?.image && (
                <BgPictureContainer>
                    {!!smallImage && <source media="(max-width:320px)" srcSet={smallImage} />}
                    {!!mediumImage && <source media="(max-width:480px)" srcSet={mediumImage} />}
                    {!!largeImage && <source media="(max-width:640px)" srcSet={largeImage} />}
                    {!!desktopImage && <source media="(max-width:1024px)" srcSet={desktopImage} />}
                    {!!wideScreenImage && <BgImageContainer src={wideScreenImage} alt="hero image" />}
                </BgPictureContainer>
            )}
            <Overlay>
                <ContentWrapper>
                    <TitleWrapper>
                        <Title>{heading}</Title>
                    </TitleWrapper>
                    <SubtitleWrapper>{`${t('heroSubtitle')} ${count} ${t('vacationRentals')}`}</SubtitleWrapper>
                    <Anchor ref={stickyRef}>
                        <StickyContainer sticky={sticky}>
                            {isDesktop && sticky && !!logo?.image?.url && (
                                <LogoWrapper href={logo.url}>
                                    <LogoImage src={logo.image ? logo.image.url : ''} alt={'Logo'} />
                                </LogoWrapper>
                            )}
                            <SearchBoxWrapper sticky={sticky}>
                                <RegionsAutocompleteSuggestionPickerWrapper warning={warning} sticky={sticky}>
                                    <RegionsAutocompleteSuggestionPicker
                                        isOpen={isLocationPickerOpen}
                                        onOpen={_handleLocationPickerOpen}
                                        onClose={onLocationPickerClose}
                                        locationInput={location.search}
                                        onLocationInputChange={_handleLocationInputChange}
                                        onLocationInputSelect={_handleLocationInputSelect}
                                        onLocationItemSelect={_handleLocationItemSelect}
                                        onHistoryItemSelect={_handleHistoryItemSelect}
                                        error={locationPickerError}
                                        mode={LocationPickerMode.WIDGET}
                                    />
                                    {warning && (
                                        <div>
                                            <RedArrow />
                                            <RedCard>{t('dangerText')}</RedCard>
                                        </div>
                                    )}
                                </RegionsAutocompleteSuggestionPickerWrapper>
                                <DateInputBox
                                    datepickerState={datepickerState}
                                    setDatepickerState={setDatepickerState}
                                    sticky={sticky}
                                    url={url}
                                    arrivalText={arrival.label}
                                    departureText={departure.label}
                                />
                                <SearchBarGuestStepper
                                    val={guestsValue}
                                    setVal={setGuestsValue}
                                    minVal={minGuestsValue}
                                    maxVal={maxGuestsValue}
                                    setMinVal={setMinGuestsValue}
                                    setMaxVal={setMaxGuestsValue}
                                    sticky={sticky}
                                    url={url}
                                />
                                <ButtonWrapper>
                                    <SolidButton
                                        width={'100%'}
                                        padding={'1.09375rem'}
                                        onClick={handleSearchClick}
                                        borderRadius={
                                            isMobile
                                                ? `${theme.typography.border.radius.small}`
                                                : `0 ${theme.typography.border.radius.small}
                                                                ${theme.typography.border.radius.small} 0`
                                        }
                                    >
                                        <ButtonContentWrapper>
                                            <TextWrapper>{searchLabel}</TextWrapper>
                                        </ButtonContentWrapper>
                                    </SolidButton>
                                </ButtonWrapper>
                            </SearchBoxWrapper>
                        </StickyContainer>
                    </Anchor>
                </ContentWrapper>
            </Overlay>
        </Container>
    )
}

export default memo(SearchbarSection)
