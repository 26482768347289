import React, { FC, memo, useCallback } from 'react'

import { useTranslation } from '../../../hooks/locale'
import { ListingSectionObject } from '../../../transports/common/listing-section-transport'
import { landingPageEventCategories } from '../../../services/analytics-service/categories'
import { landingPageEventActions } from '../../../services/analytics-service/actions'
import AnalyticsService from '../../../services/analytics-service'
import VerticalPropertyCard from '../common/property-card/vertical-property-card'
import { getPropertyClickRedirectUrl } from '../utils'
import { usePropertyCardTracking } from '../hooks'
import { encodeToBase64 } from '../../../utils/encoding-utils'
import { isServer } from '../../../utils/platform-utils'

interface Props {
    recommendedObject: ListingSectionObject
    position: number
}

interface ServerProps {
    requestUrl?: string
}

const IMAGE_ASPECT_RATIO = '66.67%'

const VerticalCard: FC<Props & ServerProps> = ({ recommendedObject, position, requestUrl }) => {
    const { t } = useTranslation()

    const { propertyCardClickTracking } = usePropertyCardTracking(recommendedObject)

    const handlePropertyContentClick = useCallback(() => {
        const eventCategory = `${landingPageEventCategories.listingCardSection.TOP_INSERAT} - ${recommendedObject.href}`
        const eventAction = landingPageEventActions.listingCardSection.TOP_INSERAT
        const redirectUrl = getPropertyClickRedirectUrl(t('traumBaseUrl'), recommendedObject.href)

        // handle traumBoost tracking
        const _objectURL = new URL(redirectUrl)
        if (recommendedObject.isPremiumTopObject || recommendedObject.isTopObject) {
            // handle traumBoost tracking
            // from_page & tb will only be set for all top listings in RLP
            // from_page contains base64 encoded url of the page from which the request is made
            _objectURL.searchParams.set(
                'from_page',
                isServer ? encodeToBase64(requestUrl ?? '') : encodeToBase64(window.location.href),
            )
            _objectURL.searchParams.set('tb', '1')
        }

        propertyCardClickTracking(recommendedObject)

        AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: redirectUrl })
        window.open(_objectURL.href, '_blank')
    }, [propertyCardClickTracking, recommendedObject, t])

    return (
        <VerticalPropertyCard
            listingObject={recommendedObject}
            transitionTime={0.2}
            dragEnabled={false}
            naturalSlideHeight={1}
            naturalSlideWidth={1}
            visibleSlides={1}
            step={1}
            handleCardContentClick={handlePropertyContentClick}
            imageAspectRatio={IMAGE_ASPECT_RATIO}
            position={position}
        />
    )
}

export default memo(VerticalCard)
