import { RegionLandingPageTransport } from '../transports'
import { TranslationFunction } from '../common/types'
import {
    WithContext,
    Product,
    ItemList,
    ListItem,
    PostalAddress,
    Accommodation,
    GeoCoordinates,
    AggregateRating,
} from 'schema-dts'

export const getProductSchemaHTML = (
    landingPageTransport: RegionLandingPageTransport | null,
): { __html: string } | null => {
    if (
        !landingPageTransport ||
        !landingPageTransport.topRatedObjects ||
        !landingPageTransport.topRatedObjects.averageRating ||
        !landingPageTransport.topRatedObjects.totalRatingCount ||
        landingPageTransport.topRatedObjects.totalRatingCount <= 0 ||
        landingPageTransport.topRatedObjects.averageRating < 1
    ) {
        return null
    }

    const schema: WithContext<Product> = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: landingPageTransport.hero.description.title,
        description: landingPageTransport.head.description,
        brand: {
            '@type': 'Brand',
            name: 'Traum-Ferienwohnungen',
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            bestRating: '5',
            worstRating: '1',
            ratingValue: landingPageTransport.topRatedObjects.averageRating,
            reviewCount: landingPageTransport.topRatedObjects.totalRatingCount,
        },
    }

    if (landingPageTransport.hero.slide?.image) {
        schema.image = landingPageTransport.hero.slide?.image?.url
    }

    return {
        __html: JSON.stringify(schema),
    }
}

const _extractNumber = (value: string | undefined): number => {
    if (!value) {
        return 0
    }
    const match = value.match(/(\d+)/)
    return match ? Number.parseInt(match[0]) : 0
}

export const getItemListSchemaHTML = (
    landingPageTransport: RegionLandingPageTransport | null,
    t: TranslationFunction,
): { __html: string } | null => {
    if (
        !landingPageTransport ||
        !landingPageTransport.topRatedObjects ||
        !landingPageTransport.topRatedObjects.averageRating ||
        !landingPageTransport.topRatedObjects.totalRatingCount ||
        landingPageTransport.topRatedObjects.totalRatingCount <= 0 ||
        landingPageTransport.topRatedObjects.averageRating < 1
    ) {
        return null
    }

    if (landingPageTransport.popularDestinations && landingPageTransport.popularDestinations.regions.length > 0) {
        return null
    }

    const filteredListings = landingPageTransport.topRatedObjects.objects.filter(listing => listing.rating.average > 1)

    if (!filteredListings || filteredListings.length === 0) {
        return null
    }

    const itemListSchema: WithContext<ItemList> = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: filteredListings.slice(0, 8).map((listing, index) => {
            const aggregateRating = listing.rating.count
                ? ({
                      '@type': 'AggregateRating',
                      ratingValue: listing.rating.average,
                      reviewCount: listing.rating.count,
                      bestRating: 5,
                      worstRating: 1,
                  } as AggregateRating)
                : undefined

            const amenitySpecifications = Object.keys(listing.features)
                .map(key => ({
                    '@type': 'LocationFeatureSpecification',
                    name: key.replace(/-/g, ' '),
                    value: String(listing.features[key].state) === '1',
                }))
                .slice(0, 5)

            const geo = listing.location
                ? ({
                      '@type': 'GeoCoordinates',
                      latitude: listing.location.latitude,
                      longitude: listing.location.longitude,
                  } as GeoCoordinates)
                : undefined

            const floorSize = listing.stats.qmSize
                ? {
                      '@type': 'QuantitativeValue',
                      value: _extractNumber(listing.stats.qmSize),
                      unitCode: 'SQM',
                  }
                : undefined

            const containsPlace = {
                '@type': 'Accommodation',
                additionalType: listing.isComboListing ? 'HolidayHome' : 'EntirePlace',
                occupancy: {
                    '@type': 'QuantitativeValue',
                    value: _extractNumber(listing.stats.personCount),
                },
                numberOfBedrooms: _extractNumber(listing.stats.bedrooms),
                numberOfBathroomsTotal: _extractNumber(listing.stats.bathrooms),
                amenityFeature: amenitySpecifications,
                floorSize,
                geo,
            } as Accommodation

            return {
                '@type': 'ListItem',
                position: index + 1,
                item: {
                    '@type': 'VacationRental',
                    name: listing.apartment?.title,
                    address: {
                        '@type': 'PostalAddress',
                        addressLocality: listing.seoRegion.name,
                    } as PostalAddress,
                    image: listing.apartment?.images.map(image => image.url) ?? [],
                    url: `${t('traumBaseUrl')}${listing.seoHref}`,
                    identifier: listing.id,
                    aggregateRating,
                    geo,
                    containsPlace,
                } as unknown,
            } as ListItem
        }),
    }

    return { __html: JSON.stringify(itemListSchema) }
}
