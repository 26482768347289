import { desktopBreakpoint, mobileBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'
import styled, { AnyStyledComponent, css } from 'styled-components'
import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel'

import { LocationPinIcon } from '../../common/icons'
import { zIndexScale } from '../../../utils/style-utils'
import ExternalLink from '../../common/external-link'

export const MainContainer = styled.section`
    margin: 0 auto;
    max-width: 87.5rem;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        padding: 0 ${props => props.theme.spacing.large};
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin: 0;
    }
`
export const Container = styled.div`
    margin: 0;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 auto;
    }
`
export const MainTitle = styled.h2`
    display: ${props => props.theme.style.display.flex};
    font-family: ${({ theme }) => theme.typography.font.family.yummo};
    font-weight: ${({ theme }) => theme.typography.font.yummo.weight.bold};
    font-size: 1.25rem;
    margin: 2.5rem 1rem 0.75rem;
    color: ${props => props.theme.colors.tfwBlack87};
    letter-spacing: -0.35px;
    line-height: 1.75rem;
    justify-content: space-between;
    align-items: flex-end;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 2.25rem;
        font-weight: ${({ theme }) => theme.typography.font.yummo.weight.bold};
        margin-bottom: 1rem;
        margin-left: 0;
        letter-spacing: -1px;
        line-height: 2.625rem;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-bottom: 1.5rem;
        margin-top: 3.75rem;
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-left: 0;
    }
`

export const ObjectContainer = styled.div`
    font-family: ${props => props.theme.typography.font.family.lato};
    display: ${props => props.theme.style.display.flex};
    justify-content: ${props => props.theme.style.justifyContent.spaceBetween};
    border-bottom: ${props => props.theme.typography.border.width.xxxThin}
        ${props => props.theme.typography.border.style.solid} ${props => props.theme.colors.tfwBlackOpacity10};
    margin-bottom: ${props => props.theme.spacing.large};
    padding-bottom: ${props => props.theme.spacing.large};
    cursor: pointer;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        justify-content: ${props => props.theme.style.justifyContent.flexStart};
    }
`
export const PropertyCarouselContainer = styled.div`
    position: ${props => props.theme.style.position.relative};
    background-color: ${props => props.theme.colors.white};
    display: ${props => props.theme.style.display.block};
    min-width: 36.11%;
    margin-right: ${props => props.theme.spacing.medium};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        min-width: 30%;
        margin-right: calc(${props => props.theme.spacing.xxxSmall} + ${props => props.theme.spacing.medium});
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        min-width: 23.5%;
    }
`
export const CardContainer = styled.div`
    margin-right: ${props => props.theme.spacing.xSmall};
    border-radius: ${props => props.theme.typography.border.radius.xLarge};
    cursor: pointer;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0;
    }
`
export const FavouriteButtonContainer = styled.div(
    ({ theme }) => css`
        position: ${theme.style.position.absolute};
        top: ${theme.spacing.small};
        right: ${theme.spacing.small};
        z-index: ${zIndexScale.smallTags.xsmall};
    `,
)

export const CarouselContainer = styled.div`
    position: ${props => props.theme.style.position.relative};
    border: 0.375rem;
    transition: 200ms filter linear;
    border-radius: ${props => props.theme.typography.border.radius.xLarge}
        ${props => props.theme.typography.border.radius.xLarge} 0 0;
    overflow: hidden;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        border-radius: ${props => props.theme.typography.border.radius.xLarge};
    }
`

export const DetailContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-grow: 1;
        max-width: 43.1875rem;
        min-width: 27.25rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: unset;
        min-width: unset;
    }
`

export const ObjectDetailContainer = styled.div`
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    justify-content: space-between;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 100%;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: 28rem;
    }
    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        width: fit-content;
        max-width: 40.75rem;
    }
`
export const ObjectHeader = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: ${props => props.theme.style.alignItems.center};
    margin-top: ${props => props.theme.spacing.small};
    font-size: ${props => props.theme.typography.font.size.small};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 0;
    }
`
export const MapIcon = styled(LocationPinIcon as AnyStyledComponent)`
    height: ${props => props.theme.layout.xxSmall};
    width: ${props => props.theme.layout.xxSmall};
`
export const LocationLabel = styled.div`
    line-height: 1.5rem;
    color: ${props => props.theme.colors.tfwBlack87};
    padding: 0 ${props => props.theme.spacing.xSmall};
    &:hover {
        text-decoration: underline;
    }
`
export const RatingWrapper = styled.div`
    display: flex;
    align-items: center;
    border-left: ${props => props.theme.typography.border.width.xxxThin}
        ${props => props.theme.typography.border.style.solid} ${props => props.theme.colors.tfwBlackOpacity10};
`
export const StarIcon = styled.img`
    height: ${props => props.theme.layout.xxSmall};
    width: ${props => props.theme.layout.xxSmall};
    margin-left: calc(${props => props.theme.spacing.xSmall} + ${props => props.theme.spacing.xxxSmall});
`
export const Rating = styled(LocationLabel)`
    line-height: 1.5rem;
    border: none;
    padding-left: ${props => props.theme.spacing.xxSmall};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const ObjectTitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 0.25rem;
`

export const ObjectTitle = styled.h3`
    font-family: ${props => props.theme.typography.font.family.lato};
    font-weight: ${props => props.theme.typography.font.weight.normal};
    line-height: 1.5rem;
    margin-top: ${props => props.theme.spacing.xxSmall};
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        letter-spacing: -0.021875rem;
        margin: 0 0 ${props => props.theme.spacing.xxSmall};
        font-family: ${props => props.theme.typography.font.family.yummo};
        font-weight: ${props => props.theme.typography.font.yummo.weight.bold};
        font-size: ${props => props.theme.typography.font.size.large};
        line-height: 1.75rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: ${props => props.theme.spacing.xxxSmall};
        font-size: ${props => props.theme.typography.font.size.xLarge};
        line-height: 2rem;
        letter-spacing: -0.0175rem;
    }
`

export const SeoHref = styled(ExternalLink)`
    font: inherit;
    text-decoration: none;
`

export const ObjectTopRecommendationPill = styled.span`
    flex: 0 0 auto;
    padding: 0.25rem 0.5rem;
    margin-left: 0.5rem;
    border-radius: 0.75rem;
    font-size: 0.6875rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: ${props => props.theme.colors.tfwPurple};
    background-color: #f3f0f3;

    @media only screen and (max-width: ${mobileBreakpoint.max}px) {
        position: absolute;
        left: 1rem;
        bottom: -0.75rem;
        z-index: 2;
    }
`

export const ObjectDescription = styled.div`
    font-size: ${props => props.theme.typography.font.size.medium};
    line-height: 1.5rem;
    color: ${props => props.theme.colors.tfwBlackOpacity50};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        /* stylelint-disable value-no-vendor-prefix,property-no-vendor-prefix */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        /* stylelint-enable */
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: ${props => props.theme.typography.font.size.small};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        white-space: normal;
        -webkit-line-clamp: 2;
        font-size: ${props => props.theme.typography.font.size.medium};
    }
`
export const ObjectStats = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: ${props => props.theme.style.alignItems.center};
    margin-top: ${props => props.theme.spacing.medium};
    color: ${props => props.theme.colors.tfwBlack87};
    border-radius: ${props => props.theme.typography.border.radius.xSmall};
    width: fit-content;
    font-family: ${props => props.theme.typography.font.family.lato};
    font-weight: ${props => props.theme.typography.font.weight.bold};
    font-size: 0.6875rem;
    line-height: 1rem;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        background: ${props => props.theme.colors.tfwWhite64};
        margin-top: ${props => props.theme.spacing.medium};
        font-weight: ${props => props.theme.typography.font.weight.normal};
        font-size: 0.8125rem;
        letter-spacing: 0.0125rem;
        text-transform: none;
        padding: ${props => props.theme.spacing.xxxSmall} ${props => props.theme.spacing.xxSmall};
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: calc(${props => props.theme.spacing.xxSmall} + ${props => props.theme.spacing.medium});
        font-size: ${props => props.theme.typography.font.size.small};
        letter-spacing: normal;
        line-height: 1.5rem;
        padding: ${props => props.theme.spacing.xxxSmall} ${props => props.theme.spacing.xSmall};
    }
`
export const StatWrapper = styled.div<StatProps>`
    display: ${props => (props.visible ? props.theme.style.display.flex : 'none')};
    align-items: center;
`
interface StatProps {
    visible: boolean
}
export const Stat = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
export const DotIcon = styled.div(
    ({ theme }) => css`
        line-height: 1rem;
        font-weight: ${theme.typography.font.yummo.weight.light};
        font-size: 0.5625rem;
        margin: 0 ${theme.spacing.xxSmall};
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            font-size: inherit;
            margin: 0 ${theme.spacing.xSmall};
        }
    `,
)
export const PriceDetailContainer = styled.div`
    font-family: ${props => props.theme.typography.font.family.lato};
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    justify-content: ${props => props.theme.style.justifyContent.flexEnd};
    align-items: ${props => props.theme.style.alignItems.flexEnd};
    width: fit-content;
`
export const LeftBottomContainer = styled(PriceDetailContainer)`
    width: 100%;
    flex-direction: ${props => props.theme.style.flexDirection.row};
    margin-top: calc(${props => props.theme.spacing.xxSmall} + ${props => props.theme.spacing.medium});
    justify-content: space-between;
    align-items: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: ${props => props.theme.spacing.medium};
    }
`
export const PriceLabel = styled.p`
    font-weight: ${props => props.theme.typography.font.lato.weight.black};
    font-size: ${props => props.theme.typography.font.size.medium};
    line-height: 1.5rem;
    color: ${props => props.theme.colors.tfwBlack87};
    margin: 0;
    font-feature-settings: 'ordn' on;
    letter-spacing: normal;
    text-transform: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.large};
        line-height: 1.75rem;
        letter-spacing: -0.021875rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.xLarge};
        line-height: 2rem;
        letter-spacing: -0.0175rem;
        text-align: end;
    }
`
export const PersonDetails = styled.div`
    text-transform: uppercase;
    font-style: normal;
    font-weight: 800;
    font-size: 0.5625rem;
    line-height: 1rem;
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.tfwBlackOpacity50};
    width: 40%;
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-bottom: calc((${props => props.theme.spacing.xxSmall} + ${props => props.theme.spacing.medium}) / 2);
        width: fit-content;
    }
`
export const CTAButton = styled.div`
    background: ${props => props.theme.colors.white};
`
export const ButtonText = styled.div`
    font-weight: ${props => props.theme.typography.font.weight.bold};
    font-size: ${props => props.theme.typography.font.size.small};
    line-height: 1.5rem;
`
export const Boundary = styled.div`
    height: 0.0625rem;
    background: ${props => props.theme.colors.tfwBlackOpacity10};
    margin: ${props => props.theme.spacing.large} 0;
`

export const CtaContainer = styled.div`
    font-family: ${props => props.theme.typography.font.family.lato};
    display: ${props => props.theme.style.display.flex};
    justify-content: ${props => props.theme.style.justifyContent.center};
`
export const CtaButtonText = styled.div`
    font-family: ${props => props.theme.typography.font.family.lato};
    font-weight: ${props => props.theme.typography.font.weight.bold};
    font-size: ${props => props.theme.typography.font.size.small};
    line-height: 1.5rem;
    font-feature-settings: 'liga' off;
    color: ${props => props.theme.colors.tfwBlack87};
`
export const CtaLink = styled(CtaButtonText)`
    color: ${props => props.theme.colors.tfwBlue12};
    white-space: nowrap;
    margin-left: ${props => props.theme.spacing.xSmall};
    font-size: 0.875rem;
    line-height: 1.5rem;
`

export const SliderContainer = styled.div`
    position: ${props => props.theme.style.position.relative};
    max-width: fit-content;
    overflow: hidden;
`

export const StyledCarouselProvider = styled(CarouselProvider)<{ margin?: string; center?: boolean }>`
    width: 100%;
    margin: ${({ margin }) => (!!margin ? margin : 0)};
    li {
        list-style: none;
    }
    ${({ center }) =>
        center
            ? `& > .carousel__slider {
            padding-left: 4%;
            padding-right: 4%;
        }`
            : ''}
`

export const StyledSlider = styled(Slider)<{ $transitionTime?: number }>(
    ({ $transitionTime }) => css`
        padding: 0;
        ul {
            transition: transform ${$transitionTime ? `${$transitionTime}s` : `0.5s`};
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            will-change: transform;
        }
    `,
)

export const SortOptionsContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    justify-content: ${props => props.theme.style.justifyContent.spaceBetween};
    margin: 0 1rem ${props => props.theme.spacing.medium};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 0 calc(${props => props.theme.spacing.xSmall} + ${props => props.theme.spacing.small});
    }
`
