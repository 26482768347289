import React, { FC, memo, useCallback, useContext } from 'react'
import { ScreenContext, GhostButton } from 'react-components'
import { AnyStyledComponent, ThemeContext } from 'styled-components'

import { useTranslation } from '../../../hooks/locale'
import { ListingSectionObject } from '../../../transports/common/listing-section-transport'
import TopRecommendationCard from './image-carousel-card'
import {
    Boundary,
    ButtonText,
    CTAButton,
    DotIcon,
    LeftBottomContainer,
    PropertyCarouselContainer,
    LocationLabel,
    MapIcon,
    ObjectDetailContainer,
    ObjectContainer,
    ObjectDescription,
    ObjectHeader,
    ObjectStats,
    ObjectTitle,
    PersonDetails,
    PriceLabel,
    Rating,
    RatingWrapper,
    PriceDetailContainer,
    SeoHref,
    StarIcon,
    Stat,
    StatWrapper,
    DetailContainer,
    ObjectTitleContainer,
    ObjectTopRecommendationPill,
} from './styles'
import Features from '../../object-cards/features'
import { landingPageEventCategories } from '../../../services/analytics-service/categories'
import { landingPageEventActions } from '../../../services/analytics-service/actions'
import AnalyticsService from '../../../services/analytics-service'
import { getPropertyClickRedirectUrl } from '../utils'

import Star from '../../../assets/icons/star.svg'
import { usePropertyCardTracking } from '../hooks'
import { isServer } from '../../../utils/platform-utils'
import { encodeToBase64 } from '../../../utils/encoding-utils'
import { preventDefaultHandler } from '../../utils'

interface HorizontalCardProps {
    recommendedObject: ListingSectionObject
}

interface ServerProps {
    requestUrl?: string
}

const RATINGS_TOTAL = 5

const HorizontalCard: FC<HorizontalCardProps & ServerProps> = ({ recommendedObject, requestUrl }) => {
    const { t } = useTranslation()
    const { isTablet } = useContext(ScreenContext)
    const theme = useContext(ThemeContext)

    const { intersectionTargetRef, propertyCardClickTracking } = usePropertyCardTracking(recommendedObject)

    const handleCardClick = (href: string) => {
        const eventCategory = `${landingPageEventCategories.listingCardSection.TOP_INSERAT} - ${href}`
        const eventAction = landingPageEventActions.listingCardSection.TOP_INSERAT
        const redirectUrl = getPropertyClickRedirectUrl(t('traumBaseUrl'), recommendedObject.href)

        const _objectURL = new URL(redirectUrl)
        if (recommendedObject.isPremiumTopObject || recommendedObject.isTopObject) {
            // handle traumBoost tracking
            // from_page & tb will only be set for all top listings in RLP
            // from_page contains base64 encoded url of the page from which the request is made
            _objectURL.searchParams.set(
                'from_page',
                isServer ? encodeToBase64(requestUrl ?? '') : encodeToBase64(window.location.href),
            )
            _objectURL.searchParams.set('tb', '1')
        }

        propertyCardClickTracking(recommendedObject)

        AnalyticsService.trackEvent({ action: eventAction, category: eventCategory, label: redirectUrl })
        window.open(_objectURL.href, '_blank')
    }

    const handleRegionClick = useCallback((event: React.MouseEvent) => {
        event.stopPropagation()
    }, [])

    const handleClick = useCallback(() => {
        handleCardClick(recommendedObject.href)
    }, [handleCardClick, recommendedObject.href])

    return (
        <ObjectContainer key={recommendedObject.id} ref={intersectionTargetRef}>
            <PropertyCarouselContainer onClick={handleClick}>
                <TopRecommendationCard recommendedObject={recommendedObject} />
            </PropertyCarouselContainer>
            <DetailContainer>
                <ObjectDetailContainer onClick={handleClick}>
                    <ObjectHeader>
                        <MapIcon />
                        <LocationLabel>
                            {recommendedObject.breadcrumbs ? (
                                <SeoHref
                                    onClick={handleRegionClick}
                                    title={recommendedObject.breadcrumbs[recommendedObject.breadcrumbs.length - 1].name}
                                    href={recommendedObject.breadcrumbs[recommendedObject.breadcrumbs.length - 1].url}
                                >
                                    {recommendedObject.breadcrumbs[recommendedObject.breadcrumbs.length - 1].name}
                                </SeoHref>
                            ) : (
                                <SeoHref
                                    onClick={handleRegionClick}
                                    title={recommendedObject.seoRegion.name}
                                    href={recommendedObject.seoRegion.url}
                                    noCrawl={recommendedObject.seoRegion.url.noCrawl}
                                >
                                    {recommendedObject.seoRegion.name}
                                </SeoHref>
                            )}
                        </LocationLabel>
                        {recommendedObject.rating.count !== 0 ? (
                            <RatingWrapper>
                                <StarIcon as={Star as AnyStyledComponent} />
                                <Rating>
                                    {`
                                    ${recommendedObject.rating.average}/${RATINGS_TOTAL}
                                    (${recommendedObject.rating.count} ${t('reviewText')})`}
                                </Rating>
                            </RatingWrapper>
                        ) : null}
                    </ObjectHeader>
                    <ObjectTitleContainer>
                        {!!recommendedObject.apartment && (
                            <ObjectTitle>
                                <SeoHref
                                    title={recommendedObject.apartment.title}
                                    href={recommendedObject.seoHref}
                                    onClick={preventDefaultHandler}
                                    autoRedirect={false}
                                >
                                    {recommendedObject.apartment.title}
                                </SeoHref>
                            </ObjectTitle>
                        )}
                        {!!recommendedObject.isTopObject && (
                            <ObjectTopRecommendationPill>{t('topListing')}</ObjectTopRecommendationPill>
                        )}
                    </ObjectTitleContainer>
                    <ObjectDescription>{recommendedObject.excerpt}</ObjectDescription>
                    <ObjectStats>
                        {!!recommendedObject.stats &&
                            Object.keys(recommendedObject.stats).map((key, index) => {
                                return (
                                    <StatWrapper
                                        visible={!!recommendedObject.stats[key] && key !== t('improperBedrooms')}
                                        key={`${recommendedObject.stats[key]}_${index}`}
                                    >
                                        <Stat>{recommendedObject.stats[key]}</Stat>
                                        {key !== t('qmSize') ? <DotIcon>&#8226;</DotIcon> : ''}
                                    </StatWrapper>
                                )
                            })}
                    </ObjectStats>
                    <Features features={recommendedObject.features} />
                    {isTablet && (
                        <LeftBottomContainer>
                            {!!recommendedObject.price && (
                                <PersonDetails>
                                    <PriceLabel>{`${recommendedObject.price.price}`}</PriceLabel>
                                    {recommendedObject.price.persons &&
                                        recommendedObject.price.unit &&
                                        `${recommendedObject.price.persons} / ${recommendedObject.price.unit}`}
                                </PersonDetails>
                            )}
                            <CTAButton>
                                <GhostButton
                                    hoverEffect
                                    color={`${theme.colors.tfwBlack87}`}
                                    backgroundColor={`${theme.colors.white}`}
                                    hoverColor={`${theme.colors.tfwBlack87}`}
                                    hoverBorderColor={`${theme.colors.tfwYellowHover}`}
                                    hoverBackgroundColor={`${theme.colors.tfwYellowHover}`}
                                    padding={`${theme.spacing.xSmall} ${theme.spacing.medium}`}
                                >
                                    <ButtonText>{t('sliderCTA')}</ButtonText>
                                </GhostButton>
                            </CTAButton>
                        </LeftBottomContainer>
                    )}
                </ObjectDetailContainer>
                {!isTablet && (
                    <PriceDetailContainer onClick={handleClick}>
                        <PriceLabel>{`${recommendedObject.price.price}`}</PriceLabel>
                        {!!recommendedObject.price && (
                            <PersonDetails>
                                {recommendedObject.price.persons &&
                                    recommendedObject.price.unit &&
                                    `${recommendedObject.price.persons} / ${recommendedObject.price.unit}`}
                            </PersonDetails>
                        )}
                        <CTAButton>
                            <GhostButton
                                hoverEffect
                                color={`${theme.colors.tfwBlack87}`}
                                backgroundColor={`${theme.colors.white}`}
                                hoverColor={`${theme.colors.tfwBlack87}`}
                                hoverBorderColor={`${theme.colors.tfwYellowHover}`}
                                hoverBackgroundColor={`${theme.colors.tfwYellowHover}`}
                                padding={`${theme.spacing.xSmall} ${theme.spacing.medium}`}
                            >
                                <ButtonText>{t('sliderCTA')}</ButtonText>
                            </GhostButton>
                        </CTAButton>
                    </PriceDetailContainer>
                )}
            </DetailContainer>
            <Boundary />
        </ObjectContainer>
    )
}

export default memo(HorizontalCard)
