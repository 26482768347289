import styled, { AnyStyledComponent, css } from 'styled-components'
import { desktopBreakpoint, mobileBreakpoint, tabletBreakpoint } from 'react-components'

import { LocationPinIcon } from '../common/icons'
import Calendar from '../../assets/icons/userbar-icons/calendar.svg'
import CircledCrossMini from '../../assets/icons/userbar-icons/circled-cross-mini.svg'
import { WidgetContainer, WidgetInputContainer } from '../search-result-listing/header-section/searchbar/widget-styles'

export const Container = styled.section`
    height: 22.5rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        position: ${({ theme }) => theme.style.position.relative};
        height: 20rem;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: 22.5rem;
    }
`
export const Overlay = styled.div`
    position: absolute;
    width: ${({ theme }) => theme.layout.fill};
    display: block;
    background-color: #21212166;

    @media only screen and (max-width: ${mobileBreakpoint.max}px) {
        height: 22.5rem;
        top: 3.25rem;
    }

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 20rem;
        top: 0;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: 22.5rem;
    }
`

export const ContentWrapper = styled.div`
    position: ${({ theme }) => theme.style.position.relative};
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    width: 100%;
    align-items: center;
    padding: 0 0.625rem;
    line-height: 1.6;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        top: 1.875rem;
        padding: 0.625rem 0;
        bottom: 3rem;
        justify-content: ${({ theme }) => theme.style.justifyContent.center};
        position: ${({ theme }) => theme.style.position.absolute};
    }
`

export const SubtitleWrapper = styled.span`
    font-family: ${props => props.theme.typography.font.family.lato};
    text-align: center;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.typography.font.size.small};
    line-height: 1.5rem;
    font-weight: ${props => props.theme.typography.font.weight.normal};
    text-shadow: ${props => `0 0 0.625rem ${props.theme.colors.tfwBlack87}`};
    margin-bottom: ${props => props.theme.spacing.xLarge};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.medium};
        font-weight: ${props => props.theme.typography.font.weight.bold};
        font-feature-settings: 'ordn' on;
        margin-bottom: ${props => props.theme.spacing.large};
        width: ${({ theme }) => theme.layout.fill};
        margin-left: 3.375rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: 61.875rem;
        margin: 0 1.875rem ${props => props.theme.spacing.large};
        font-size: ${({ theme }) => theme.typography.font.size.large};
        font-weight: 900;
        line-height: 1.75rem;
        letter-spacing: -0.021875rem;
    }
`

export const Title = styled.h1`
    font-size: 1.625rem;
    font-weight: ${props => props.theme.typography.font.weight.bold};
    color: inherit;
    font-family: inherit;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.xxLarge};
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: 2.75rem;
    }
`

export const TitleWrapper = styled.div`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.typography.font.family.yummo};
    text-shadow: ${props => `0 0 0.625rem ${props.theme.colors.tfwBlack87}`};
    margin-bottom: ${props => props.theme.spacing.xSmall};
    margin-top: ${props => props.theme.spacing.xLarge};
    text-align: center;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        letter-spacing: -0.05rem;
        width: ${({ theme }) => theme.layout.fill};
        margin: ${props => props.theme.spacing.large} 3.375rem ${props => props.theme.spacing.medium};
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: 61.875rem;
        margin: 0 1.875rem ${props => props.theme.spacing.medium};
        letter-spacing: -0.03125rem;
    }
`

export const Anchor = styled.div`
    width: 100%;
    min-height: 80px; /* should be height of desktop searchbar for sticky to work smoothly */
`

interface ElementProps {
    sticky: boolean
    warning?: boolean
}

export const StickyContainer = styled.div<ElementProps>`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    width: 100%;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        transition: all 200ms ease-out;
        ${props =>
            props.sticky &&
            `
                position: fixed;
                top: 0;
                z-index: 100;
                height: 5rem;
                width: 100vw;
                background-color: ${props.theme.colors.tfwBlack3};
                box-shadow: 0 0.375rem 0.625rem 0 #21212123,0 0.0625rem 1.125rem 0 #2121210F,
                    0 0.1875rem 0.625rem 0 #21212119;
                `}
    }
`

export const LogoWrapper = styled.a(
    ({ theme }) => css`
        display: ${theme.style.display.flex};
        align-items: ${theme.style.alignItems.center};
        text-decoration: none;
        height: 100%;
        color: ${theme.colors.tfwBlack87};
        padding: 0.625rem 0 0.625rem 0.625rem;
        cursor: pointer;
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            padding: 0.625rem ${theme.spacing.xLarge} 0.625rem ${theme.spacing.medium};
        }
    `,
)

export const SearchBoxWrapper = styled.div<ElementProps>`
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    padding: 0.625rem;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    box-shadow: 0 0.5rem 0.625rem #0000000f, 0 0.1875rem 0.875rem #0000000a, 0 0.3125rem 0.3125rem #00000014;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 0 3.125rem;
        box-shadow: none;
    }

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        flex-direction: ${({ theme }) => theme.style.flexDirection.row};
        max-width: 61.875rem;
        padding: 0;
        margin: 0 1rem 0 1.875rem;
        background-color: ${props => (props.sticky ? 'transparent' : '#0000004C')};
        transition: all 200ms ease-out;
    }
`

export const IconWrapper = styled.img`
    width: 1rem;
`

// Searchbar-datepicker components

export const DateInputContainer = styled.div<ElementProps>`
    position: ${({ theme }) => theme.style.position.relative};
    display: ${({ theme }) => theme.style.display.flex};
    width: 100%;
    height: 3.75rem;
    margin-bottom: 0.625rem;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    background-color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    color: ${({ theme }) => theme.colors.tfwBlack45};
    font-weight: 300;
    align-items: ${({ theme }) => theme.style.alignItems.center};
    justify-content: ${({ theme }) => theme.style.justifyContent.flexStart};
    border: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} #0000001e;
    box-sizing: border-box;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${({ theme }) => theme.spacing.small} 0;
        width: 33%;
        border-right: ${({ theme }) => theme.typography.border.width.xxxThin} solid #0000001a;
        border-left: ${({ theme }) => theme.typography.border.width.xxxThin} solid #0000001a;
        border-radius: 0;
        min-width: 17.5rem;
        color: ${({ theme }) => theme.colors.tfwBlack87};
        font-weight: 600;
    }

    ${props =>
        props.sticky &&
        `
            border: 1px solid ${props.theme.colors.tfwBlack12};
            `}
`

interface Props {
    clicked?: boolean
    empty?: boolean
}

export const LeftContainer = styled.div<Props>`
    display: ${({ theme }) => theme.style.display.flex};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    height: 100%;
    border-radius: ${({ theme }) => theme.typography.border.radius.small} 0 0
        ${({ theme }) => theme.typography.border.radius.small};
    background-color: ${({ theme }) => theme.colors.white};
    padding-left: ${props => props.theme.spacing.medium};
`

export const CalendarIconWrapper = styled(Calendar as AnyStyledComponent)<{ $clicked: boolean }>`
    width: 1rem;
    cursor: pointer;
`
export const DateSeparator = styled.div`
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    line-height: 1.5rem;
`

export const RightContainer = styled(LeftContainer)`
    border-radius: 0 ${({ theme }) => theme.typography.border.radius.small}
        ${({ theme }) => theme.typography.border.radius.small} 0;
    padding-left: 0;
`

export const InputWrapper = styled.div`
    width: 100%;
    display: ${({ theme }) => theme.style.display.flex};
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
`

export const LabelWrapper = styled.div<Props>`
    position: ${({ theme }) => theme.style.position.absolute};
    top: 0;
    padding: ${props => (props.clicked ? '0.5rem 0.75rem' : '1.125rem 0.75rem')};
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    transition: all 200ms ease-out;
    ${props => props.clicked && props.empty && `color: ${props.theme.colors.tfwBlack87}`};
`

export const DateStyles = styled.div<Props>`
    position: ${({ theme }) => theme.style.position.relative};
    padding: 0 0.75rem;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    font-weight: ${props => (props.clicked ? '600' : '400')};
    transition: opacity 300ms;
`

// Searchbar-location components

export const LocationInputContainer = styled.div<ElementProps>`
    position: ${({ theme }) => theme.style.position.relative};
    display: ${({ theme }) => theme.style.display.flex};
    width: 100%;
    height: 3.75rem;
    margin-bottom: 0.625rem;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    background-color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    color: ${props => (props.warning ? '#af0d21' : props.theme.colors.tfwBlack87)};
    font-weight: 300;
    align-items: ${({ theme }) => theme.style.alignItems.center};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    padding: ${props => (props.warning ? '0 0.875rem' : '0 1rem')};
    border: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} #0000001e;
    box-sizing: border-box;
    ${props =>
        props.warning &&
        `
            border: 2px solid #af0d21;
            `};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${({ theme }) => theme.spacing.small};
        margin-right: 0;
        border-radius: ${({ theme }) => theme.typography.border.radius.small} 0 0
            ${({ theme }) => theme.typography.border.radius.small};
        width: 40%;
        font-weight: 600;
        font-size: ${({ theme }) => theme.typography.font.size.medium};
        line-height: 1.5rem;
        border-right: none;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 33%;
    }

    ${props =>
        props.sticky &&
        `
            border: 1px solid ${props.theme.colors.tfwBlack12};
        `}
`

export const Label = styled.label`
    display: flex;
    width: 100%;
    height: 100%;
`

export const LocationInputWrapper = styled(InputWrapper)<{ warning: boolean }>`
    position: relative;
    ${props => props.warning && 'top: -2px;'}
`

export const InputStyles = styled.input<Props>`
    position: ${({ theme }) => theme.style.position.relative};
    bottom: 0;
    padding: 0 ${props => props.theme.spacing.small} 0 0;
    width: 100%;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    font-weight: 400;
    opacity: ${props => (props.clicked ? 1 : 0)};
    transition: opacity 200ms;
    outline: none;
    border: none;
`

export const PinIconWrapper = styled(LocationPinIcon as AnyStyledComponent)<{ clicked: boolean; warning: boolean }>`
    height: 100%;
    width: 1rem;
    margin-right: ${props => props.theme.spacing.xSmall};
    ${props =>
        props.warning &&
        'filter: invert(12%) sepia(92%) saturate(3766%) hue-rotate(343deg) brightness(93%) contrast(102%);'}
`

export const ResetIconWrapper = styled(CircledCrossMini)`
    width: 1.125rem;
    cursor: pointer;
`

export const HistoryContainer = styled.div<Props>`
    position: ${({ theme }) => theme.style.position.absolute};
    display: ${({ theme }) => theme.style.display.flex};
    top: 4.375rem;
    width: 100%;
    flex-direction: ${({ theme }) => theme.style.flexDirection.column};
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 10;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    border-radius: 0 0
        ${({ theme }) => theme.typography.border.radius.small + ' ' + theme.typography.border.radius.small};
    padding-bottom: ${props => props.theme.spacing.xSmall};
    box-shadow: 0 0.1875rem 0.1875rem 0 #21212123, 0 0.0625rem 0.25rem 0 #2121210f, 0 0.0625rem 1rem 0 #21212119;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        top: 4rem;
        left: 0;
        width: max-content;
        border: ${({ theme }) => theme.typography.border.width.xxxThin}
            ${({ theme }) => theme.typography.border.style.solid} #00000002;
        border-radius: ${({ theme }) => theme.typography.border.radius.small};
        box-sizing: border-box;
        box-shadow: 0 0.5rem 0.625rem #0000000f, 0 0.1875rem 0.875rem #0000000a, 0 0.3125rem 0.3125rem #00000014;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 200%;
    }
`

export const SuggestionsItem = styled.div<{ selected: boolean }>`
    display: flex;
    padding: ${props => props.theme.spacing.xSmall} ${props => props.theme.spacing.small};
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tfwBlack87};
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
    ${props => props.selected && `background-color: ${props.theme.colors.tfwBlack12};`};
    &:hover {
        background-color: ${({ theme }) => theme.colors.tfwBlack12};
        cursor: pointer;
    }
`

export const HistoryItem = styled.div<{ selected?: boolean }>`
    padding: ${props => props.theme.spacing.xSmall} ${props => props.theme.spacing.medium};
    font-weight: 700;
    display: ${({ theme }) => theme.style.display.flex};
    color: ${({ theme }) => theme.colors.tfwBlack87};
    align-items: center;
    ${props => props.selected && `background-color: ${props.theme.colors.tfwBlack12};`};

    :nth-child(n + 2) {
        cursor: pointer;

        :hover {
            background-color: ${({ theme }) => theme.colors.tfwBlack12};
        }
    }
`
export const HistoryItemLabel = styled.div`
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 1rem;
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    color: ${props => props.theme.colors.tfwBlackOpacity50};
    margin: ${props => `${props.theme.spacing.medium} ${props.theme.spacing.medium}
        ${props.theme.spacing.xSmall}`};
`
export const ItemName = styled.div`
    margin: 0 ${({ theme }) => theme.spacing.xSmall} 0 0;
    font-size: ${props => props.theme.typography.font.size.small};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.medium};
    }
`
export const HistoryItemData = styled.div`
    display: flex;
    font-weight: ${props => props.theme.typography.font.weight.bold};
    color: ${props => props.theme.colors.tfwBlackOpacity50};
    letter-spacing: 0.0125rem;
    font-size: ${props => props.theme.typography.font.size.xSmall};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        font-size: ${props => props.theme.typography.font.size.small};
        letter-spacing: normal;
        font-weight: normal;
    }
`
export const Separator = styled.div`
    height: ${({ theme }) => theme.layout.xxSmall};
    color: ${props => props.theme.colors.tfwBlackOpacity10};
    align-self: center;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        align-self: flex-start;
    }
`
export const HistoryItemDescription = styled.div`
    margin: 0 ${({ theme }) => theme.spacing.xSmall};
`
export const SuggestionsContainer = styled(HistoryContainer)`
    font-weight: 400;
    padding: ${props => props.theme.spacing.xSmall} 0;
`

export const SuggestionsItemLocation = styled.div`
    display: flex;
    margin-right: ${props => props.theme.spacing.small};

    > img {
        margin-right: ${props => props.theme.spacing.xSmall};
    }
`

export const SuggestionsItemTitle = styled.div`
    font-size: ${props => props.theme.typography.font.size.small};
`

export const SuggestionsItemData = styled.div`
    font-size: 0.8125rem;
    font-weight: normal;
    padding: ${props => props.theme.spacing.xxSmall} ${props => props.theme.spacing.xSmall};
    color: ${props => props.theme.colors.tfwBlackOpacity50};
    letter-spacing: 0.2px;
    line-height: 1rem;
    border-radius: ${({ theme }) => theme.typography.border.radius.xSmall};
    background-color: ${({ theme }) => theme.colors.tfwWhite64};
`

export const RedArrow = styled.div`
    border-radius: 0.125rem;
    position: absolute;
    left: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    transform: rotate(45deg);
    z-index: 10;
    top: 3.5rem;
    background-color: #af0d21;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        top: 3.75rem;
    }
`

export const RedCard = styled.div`
    line-height: 1.15;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    color: #fff;
    padding: 0.625rem;
    background-color: #af0d21;
    z-index: 11;
    position: absolute;
    max-width: 100%;
    left: 0;
    top: 3.875rem;
    font-size: 0.875rem;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        top: 4.125rem;
    }
`

// Number of Guests Components

export const DropdownContainer = styled.div<ElementProps>`
    position: ${({ theme }) => theme.style.position.relative};
    display: ${({ theme }) => theme.style.display.flex};
    width: 100%;
    height: 3.75rem;
    margin-bottom: 0.625rem;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    background-color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    color: ${({ theme }) => theme.colors.tfwBlack87};
    font-weight: 400;
    align-items: ${({ theme }) => theme.style.alignItems.center};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    padding: 0 1rem;
    cursor: default;
    border: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} #0000001e;
    box-sizing: border-box;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${({ theme }) => theme.spacing.small} 0;
        border-radius: 0;
        width: 33%;
        border-left: none;
    }

    ${props =>
        props.sticky &&
        `
            border: 1px solid ${props.theme.colors.tfwBlack12};
            `}
`
export const NewDropdownContainer = styled.div`
    position: ${({ theme }) => theme.style.position.absolute};
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.spaceBetween};
    align-items: ${({ theme }) => theme.style.alignItems.center};
    right: 0;
    top: 4rem;
    width: 100%;
    background: ${({ theme }) => theme.colors.white};
    border: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} ${props => props.theme.colors.tfwBlackOpacity10};
    box-sizing: border-box;
    box-shadow: 0 0.5rem 0.625rem #0000000f, 0.1875rem 0.875rem #0000000a, 0 0.3125rem 0.3125rem #00000014;
    border-radius: ${({ theme }) => theme.typography.border.radius.small};
    padding: ${({ theme }) => theme.spacing.large};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 170%;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 100%;
    }
`
export const DropDownLabel = styled.div`
    background: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.typography.font.size.medium};
    font-weight: 600;
    line-height: 1.5rem;
`
export const GuestsChange = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`
export const GuestsChangeButton = styled.img`
    filter: none;
    height: ${({ theme }) => theme.layout.small};
    width: ${({ theme }) => theme.layout.small};
    padding: 0 ${({ theme }) => theme.spacing.xxSmall};
    border: ${({ theme }) => theme.typography.border.width.xxxThin}
        ${({ theme }) => theme.typography.border.style.solid} ${props => props.theme.colors.tfwBlackOpacity10};
    border-radius: ${({ theme }) => theme.typography.border.radius.xSmall};
    cursor: pointer;
`
export const GuestsValueLabel = styled.div`
    background: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.typography.font.size.small};
    margin: 0 ${({ theme }) => theme.spacing.large};
`
export const GreyIconWrapper = styled(IconWrapper)`
    filter: none;
`
// Button components

export const ButtonWrapper = styled.div`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: ${({ theme }) => theme.spacing.small};
        margin-left: 0;
        width: 15%;
    }
`

export const ButtonContentWrapper = styled.div`
    display: ${({ theme }) => theme.style.display.flex};
    justify-content: ${({ theme }) => theme.style.justifyContent.center};
    align-items: ${({ theme }) => theme.style.alignItems.center};
`

export const TextWrapper = styled.div`
    font-family: ${({ theme }) => theme.typography.font.family.lato};
    font-size: 1rem;
    margin-left: 0.5rem;
`

export const RegionsAutocompleteSuggestionPickerWrapper = styled.div<{ warning: boolean; sticky: boolean }>(
    ({ theme, warning, sticky }) => css`
        position: relative;
        background: ${theme.colors.white};
        margin: ${theme.spacing.small} 0 ${theme.spacing.small} ${theme.spacing.small};
        border-radius: ${theme.typography.border.radius.small} 0 0 ${theme.typography.border.radius.small};
        height: 3.75rem;
        border: ${({ theme }) => theme.typography.border.width.xxxThin}
            ${({ theme }) => theme.typography.border.style.solid} #0000001e;

        ${warning &&
        css`
            color: #af0d21;
            border: 2px solid #af0d21;

            & svg {
                filter: invert(12%) sepia(92%) saturate(3766%) hue-rotate(343deg) brightness(93%) contrast(102%);
            }

            & input::placeholder {
                color: #af0d21;
            }
        `};

        ${sticky &&
        css`
            border: 1px solid ${theme.colors.tfwBlack12};
            border-right: 0;
        `};

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            width: 33%;
        }

        @media only screen and (max-width: ${mobileBreakpoint.max}px) {
            width: ${theme.layout.fill};
            margin: 0 0 ${theme.spacing.small} 0;
            border-radius: ${theme.typography.border.radius.small};

            & ${WidgetInputContainer} {
                margin-left: ${theme.spacing.xxSmall};
            }
        }

        & > ${WidgetContainer} {
            height: ${theme.layout.fill};
            z-index: 2;

            & > ${WidgetInputContainer} {
                border: 0;
                height: ${theme.layout.fill};

                & > input {
                    font-size: ${theme.typography.font.size.medium};
                }
            }
        }
    `,
)

export const BgImageContainer = styled.img`
    position: relative;
    width: ${({ theme }) => theme.layout.fill};
    height: 22.5rem;
    object-fit: cover;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 20rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        height: 22.5rem;
    }
`
export const BgPictureContainer = styled.picture`
    display: block;
    width: 100%;
    height: auto;
`
