import styled from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const OuterWrapper = styled.div`
    font-size: ${props => props.theme.typography.font.size.small};
    font-family: ${props => props.theme.typography.font.family.latoVerdana};
    display: ${props => props.theme.style.display.flex};
    flex-wrap: ${props => props.theme.style.flexWrap.wrap};
`
export const InnerWrapper = styled.div`
    flex-basis: ${props => props.theme.layout.fill};
    max-width: ${props => props.theme.layout.fill};
    width: ${props => props.theme.layout.fill};
    padding: 0 0.625rem 0 0;
`

export const OuterSection = styled.div`
    display: ${props => props.theme.style.display.block};
    white-space: nowrap;
    overflow-x: auto;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`

export const PopularFilterLink = styled.a`
    margin-right: 0.25rem;
    line-height: 1.5rem;
    color: ${props => props.theme.colors.tfwBlack87};
    background: ${props => props.theme.colors.white};
    display: ${props => props.theme.style.display.inlineFlex};
    padding: 0.25rem 0.75rem;
    border: 1px solid ${props => props.theme.colors.tfwBlackOpacity10};
    text-decoration: none;
    align-items: ${props => props.theme.style.alignItems.center};
    border-radius: 2.5rem;
    font-weight: ${props => props.theme.typography.font.weight.normal};
    text-align: ${props => props.theme.style.textAlign.center};
    font-size: ${props => props.theme.typography.font.size.small};
    cursor: pointer;
    :hover {
        color: ${props => props.theme.colors.tfwBlack87};
    }

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-right: 0.75rem;
    }
`
