import React, { FC, Fragment, memo, useCallback, useContext, useMemo, useState } from 'react'
import { ScreenContext } from 'react-components'
import dynamic from 'next/dynamic'

import { TipSectionTransport, TipTransport } from '../../transports/region-landing-page-transport'
import {
    Container,
    SectionContainer,
    StyledCarouselProvider,
    StyledSlider,
    TipsSectionHeading,
    TipsSectionSubHeading,
    StyledSlide,
} from './tips-section-styles'
import TipsSectionCard from './tips-section-card'
import TipsSectionOverlay from './tips-section-overlay'
import { CallbackFunction } from '../../common/types'
import { useTranslation } from '../../hooks/locale'

const TipsSectionDesktop = dynamic(() => import('./tips-section-desktop'))

type Props = {
    tipsSection: TipSectionTransport
    location: string
}

const getTipsSectionItems = (tips: TipTransport[], toggleOverlay: CallbackFunction, location: string) =>
    tips.map((tip, index) => (
        <StyledSlide key={tip.title} index={index}>
            <TipsSectionCard tip={tip} toggleOverlay={toggleOverlay} location={location} />
        </StyledSlide>
    ))

const TipsSection: FC<Props> = ({ tipsSection, location }) => {
    const { heading, tips } = tipsSection
    const { t } = useTranslation()
    const [overlayVisible, setShowOverlay] = useState<boolean>(false)
    const [currentTip, setCurrentTip] = useState<TipTransport | null>(null)

    const tipsSectionHeading = heading ? heading : `${t('tipsSectionHeading')} ${location}`

    const toggleOverlay = useCallback(
        (tip?: TipTransport) => {
            setShowOverlay(overlayVisible => !overlayVisible)
            setCurrentTip(tip || null)
        },
        [setShowOverlay, setCurrentTip],
    )

    const tipsSectionItems = useMemo(
        () => getTipsSectionItems(tips, toggleOverlay, location),
        [tips, toggleOverlay, location],
    )

    const { isDesktop, isMobile } = useContext(ScreenContext)

    return (
        <Container id="tips-section">
            {!isDesktop && (
                <Fragment>
                    <SectionContainer>
                        <TipsSectionHeading>{tipsSectionHeading}</TipsSectionHeading>
                        {!isMobile && (
                            <TipsSectionSubHeading>
                                {t('tipsSectionSubHeading')}
                                {location}
                            </TipsSectionSubHeading>
                        )}
                        <StyledCarouselProvider
                            totalSlides={tipsSectionItems.length}
                            visibleSlides={isMobile ? 1.1 : 1.2}
                            naturalSlideWidth={1}
                            naturalSlideHeight={1}
                            isIntrinsicHeight
                            lockOnWindowScroll
                            dragStep={1}
                            step={1}
                        >
                            <StyledSlider>{tipsSectionItems}</StyledSlider>
                        </StyledCarouselProvider>
                    </SectionContainer>
                    {overlayVisible && currentTip && (
                        <TipsSectionOverlay tip={currentTip} toggleOverlay={toggleOverlay} />
                    )}
                </Fragment>
            )}
            {isDesktop && <TipsSectionDesktop tipsSection={tipsSection} location={location} />}
        </Container>
    )
}

export default memo(TipsSection)
