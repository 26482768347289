import React, {
    FC,
    Fragment,
    memo,
    MouseEventHandler,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { ScreenContext } from 'react-components'
import debounce from 'lodash/debounce'
import { AnyStyledComponent } from 'styled-components'

import { FAQTransport, RegionOverviewSectionTransport } from '../../transports/region-landing-page-transport'
import {
    ArrowContainer,
    AnswerWrapper,
    Container,
    FaqsWrapper,
    HeaderArrow,
    HeaderContainer,
    QAContainer,
    QuestionWrapper,
    SectionContainer,
    Title,
    RegionSlider,
    RegionContainerForSlider,
    RegionArrow,
    QAContent,
} from './region-overview-styles'
import RegionOverviewType from './region-overview-type'
import RegionOverviewHeader from './region-overview-header'
import { useTranslation } from '../../hooks/locale'

import ChevronRightIcon from '../../assets/icons/chevron-right.svg'
import ChevronLeftIcon from '../../assets/icons/chevron-left.svg'
import ReactMarkdown from 'react-markdown'

interface Props {
    faqs: FAQTransport[]
    regionOverviewSection: RegionOverviewSectionTransport
    location: string
}

interface ArrowProps {
    className?: string
    onClick?: MouseEventHandler
    isVisible: boolean
}

const SCROLL_OFFSET = 200
const DELAY_TIME_ON_RESIZE = 500
const DELAY_TIME_ON_SCROLL = 200
const EXTRA_INITIAL_RENDER_DELAY = 2000

const getRichSnippetHtml = (faqs: FAQTransport[]) => {
    const QASchema = faqs.map(faq => {
        return {
            '@type': 'Question',
            name: faq.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: faq.answer,
            },
        }
    })

    return {
        __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: QASchema,
        }),
    }
}

export const CustomPrevArrow: FC<ArrowProps> = ({ className, onClick, isVisible }) => (
    <ArrowContainer className={className} onClick={onClick}>
        {isVisible && <HeaderArrow as={ChevronLeftIcon as AnyStyledComponent} />}
    </ArrowContainer>
)

export const CustomNextArrow: FC<ArrowProps> = ({ className, onClick, isVisible }) => (
    <Fragment>
        {isVisible && (
            <ArrowContainer className={className} onClick={onClick}>
                <HeaderArrow as={ChevronRightIcon as AnyStyledComponent} />
            </ArrowContainer>
        )}
    </Fragment>
)

const RegionOverviewSection: FC<Props> = ({ faqs, regionOverviewSection, location }) => {
    const { heading, regionOverviewList } = regionOverviewSection
    const { t } = useTranslation()
    const { isDesktop, isWideScreen } = useContext(ScreenContext)
    const [enableLeftArrow, setEnableLeftArrow] = useState(true)
    const [enableRightArrow, setEnableRightArrow] = useState(true)
    const [clickedIndex, setClickedIndex] = useState(0)
    const richSnippetHtml = useMemo(() => getRichSnippetHtml(faqs), [faqs])
    const regionScrollBarContainer = useRef<HTMLDivElement>(null)
    const leftArrowRef = useRef<HTMLDivElement>(null)
    const rightArrowRef = useRef<HTMLDivElement>(null)
    const regionOverviewSectionTitle = heading ? heading : `${t('regionOverviewSectionTitle')} ${location}`

    const goToIthSlide = (index: number) => {
        setClickedIndex(index)
    }

    const arrowEnableDisable = useCallback(() => {
        if (isDesktop || isWideScreen) {
            const currentHorizontalScroll = regionScrollBarContainer.current?.scrollLeft ?? 0
            if (currentHorizontalScroll === 0) {
                setEnableLeftArrow(false)
            } else {
                setEnableLeftArrow(true)
            }
            const scrollWidthRegion = regionScrollBarContainer.current?.scrollWidth ?? 0
            const clientWidthRegion = regionScrollBarContainer.current?.clientWidth ?? 0
            if (currentHorizontalScroll === 0 && scrollWidthRegion > clientWidthRegion) {
                if (scrollWidthRegion - clientWidthRegion > 1) {
                    setEnableRightArrow(true)
                } else {
                    setEnableRightArrow(false)
                }
            } else if (scrollWidthRegion - clientWidthRegion === currentHorizontalScroll) {
                setEnableRightArrow(false)
            } else {
                setEnableRightArrow(true)
            }
        }
    }, [isDesktop, isWideScreen])

    const onLeftArrowClick = useCallback(() => {
        if (regionScrollBarContainer.current) {
            regionScrollBarContainer.current.scrollLeft = regionScrollBarContainer.current.scrollLeft - SCROLL_OFFSET
        }
    }, [])

    const onRightArrowClick = useCallback(() => {
        if (regionScrollBarContainer.current) {
            regionScrollBarContainer.current.scrollLeft = regionScrollBarContainer.current.scrollLeft + SCROLL_OFFSET
        }
    }, [])

    const delayedQueryOnResize = useCallback(debounce(arrowEnableDisable, DELAY_TIME_ON_RESIZE), [
        isDesktop,
        isWideScreen,
    ])

    const delayedQueryOnScroll = useCallback(debounce(arrowEnableDisable, DELAY_TIME_ON_SCROLL), [
        isDesktop,
        isWideScreen,
    ])

    useEffect(() => {
        arrowEnableDisable()
        window.addEventListener('resize', delayedQueryOnResize)
        return function cleanupListener() {
            window.removeEventListener('resize', delayedQueryOnResize)
        }
    }, [isWideScreen, isDesktop])

    useEffect(() => {
        setTimeout(() => arrowEnableDisable(), EXTRA_INITIAL_RENDER_DELAY)
    }, [])

    return (
        <SectionContainer id="region-overview-section">
            {!!faqs && faqs.length > 0 && (
                <script type="application/ld+json" dangerouslySetInnerHTML={richSnippetHtml} />
            )}
            <Title>{regionOverviewSectionTitle}</Title>
            {
                <Container id="region-overview-section">
                    <HeaderContainer>
                        <RegionSlider>
                            {enableLeftArrow ? (
                                <RegionArrow ref={leftArrowRef} arrowType="left" onClick={onLeftArrowClick}>
                                    <HeaderArrow as={ChevronLeftIcon as AnyStyledComponent} />
                                </RegionArrow>
                            ) : (
                                ''
                            )}

                            <RegionContainerForSlider
                                ref={regionScrollBarContainer}
                                onScroll={isDesktop || isWideScreen ? delayedQueryOnScroll : undefined}
                            >
                                {!!faqs && faqs.length > 0 && (
                                    <RegionOverviewHeader
                                        title={t('faqTitle')}
                                        index={0}
                                        goToSlide={goToIthSlide}
                                        isSelected={clickedIndex === 0}
                                    />
                                )}
                                {!!regionOverviewList &&
                                    regionOverviewList.map((item, index) => (
                                        <RegionOverviewHeader
                                            key={item.content}
                                            title={item.title}
                                            index={faqs && faqs.length > 0 ? index + 1 : index}
                                            goToSlide={goToIthSlide}
                                            isSelected={clickedIndex === (faqs && faqs.length > 0 ? index + 1 : index)}
                                        />
                                    ))}
                            </RegionContainerForSlider>

                            {enableRightArrow ? (
                                <RegionArrow ref={rightArrowRef} arrowType="right" onClick={onRightArrowClick}>
                                    <HeaderArrow as={ChevronRightIcon as AnyStyledComponent} />
                                </RegionArrow>
                            ) : (
                                ''
                            )}
                        </RegionSlider>
                    </HeaderContainer>
                    <QAContainer>
                        {!!faqs && faqs.length > 0 && (
                            <QAContent active={clickedIndex === 0}>
                                <QuestionWrapper>{t('faqTitle')}</QuestionWrapper>
                                <FaqsWrapper>
                                    {!!faqs &&
                                        faqs.map(item => (
                                            <Fragment key={item.question}>
                                                <QuestionWrapper>{item.question}</QuestionWrapper>
                                                <AnswerWrapper>
                                                    <ReactMarkdown>{item.answer}</ReactMarkdown>
                                                </AnswerWrapper>
                                            </Fragment>
                                        ))}
                                </FaqsWrapper>
                            </QAContent>
                        )}
                        {!!regionOverviewList &&
                            regionOverviewList.map((item, index) => (
                                <QAContent
                                    key={item.title}
                                    active={clickedIndex === (faqs && faqs.length > 0 ? index + 1 : index)}
                                >
                                    <RegionOverviewType
                                        title={item.title}
                                        content={item.content}
                                        regionSectionImages={item.images}
                                    />
                                </QAContent>
                            ))}
                    </QAContainer>
                </Container>
            }
        </SectionContainer>
    )
}

export default memo(RegionOverviewSection)
