import React, { FC, memo, useContext, useMemo } from 'react'
import { ScreenContext } from 'react-components'

import {
    Container,
    DestinationsColumn,
    DestinationsMobileContainer,
    SliderContainer,
    StyledCarouselProvider,
    StyledSlider,
    SubTitle,
    Title,
} from './styles'
import BestDestinationCard from '../../app-components/best-destination-card'
import {
    CMSPopularDestinationTransport,
    PopularDestinationsSectionTransport,
} from '../../../transports/region-landing-page-transport'
import { groupBestDestinations } from '../utils'
import BestDestinationsSlider from './slider'
import CarouselButtonBack from './carousel-button-back'
import CarouselButtonNext from './carousel-button-next'

type Props = {
    popularDestinationsSection: PopularDestinationsSectionTransport
    cmsPopularDestinationsSection: CMSPopularDestinationTransport
}

const getSlides = (isMobile: boolean, isDesktop: boolean, isTablet: boolean, isWideScreen: boolean) => {
    if (isMobile) {
        return 1.5
    } else if (isTablet) {
        return 2.5
    } else if (isDesktop && !isWideScreen) {
        return 3
    } else {
        return 4
    }
}

const BestDestinationsSection: FC<Props> = ({ popularDestinationsSection, cmsPopularDestinationsSection }) => {
    const { heading, subheading, regions } = popularDestinationsSection

    const { isMobile, isDesktop, isTablet, isWideScreen } = useContext(ScreenContext)
    const slides = getSlides(isMobile, isDesktop, isTablet, isWideScreen)

    const modifiedPopularDestinationList = useMemo(() => groupBestDestinations(regions, 2), [regions])

    const popularDestinationSectionHeading =
        cmsPopularDestinationsSection && cmsPopularDestinationsSection.heading
            ? cmsPopularDestinationsSection.heading
            : heading

    return (
        <Container id="best-destinations">
            <Title>{popularDestinationSectionHeading}</Title>
            <SubTitle>{subheading}</SubTitle>
            <SliderContainer>
                {!!regions && regions.length > 0 && !isMobile && (
                    <StyledCarouselProvider
                        totalSlides={regions.length}
                        visibleSlides={slides}
                        naturalSlideWidth={1}
                        naturalSlideHeight={1}
                        isIntrinsicHeight
                        step={isTablet ? 2 : slides}
                        lockOnWindowScroll
                        dragStep={isTablet ? 2 : slides}
                        $customWidth={regions.length < slides}
                    >
                        <CarouselButtonBack />
                        <StyledSlider>
                            <BestDestinationsSlider regions={regions} />
                        </StyledSlider>
                        <CarouselButtonNext numberOfSlides={regions.length} visibleSlides={slides} />
                    </StyledCarouselProvider>
                )}
                {isMobile && (
                    <DestinationsMobileContainer>
                        {modifiedPopularDestinationList.length > 0 &&
                            modifiedPopularDestinationList.map((modifiedPopularDestinationGroup, index) => (
                                <DestinationsColumn key={`${index}_best-destinations`}>
                                    {modifiedPopularDestinationGroup.map(region => (
                                        <BestDestinationCard
                                            key={region.url}
                                            url={region.url}
                                            imageUrl={region.imageUrl}
                                            title={region.title}
                                            count={region.count}
                                            noCrawl={region.noCrawl}
                                        />
                                    ))}
                                </DestinationsColumn>
                            ))}
                    </DestinationsMobileContainer>
                )}
            </SliderContainer>
        </Container>
    )
}

export default memo(BestDestinationsSection)
